// https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/add_form_lead
import axios from "axios";
let authApi1 = axios.create({
    baseURL: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/",
    headers: {
      "Content-Type": "application/json",
    },
  });
let authApi2 = axios.create({
    baseURL: "https://api.interakt.ai/v1/public/track/",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Basic M3VuOXYtWGRrdC1Pd0txWTdBNzNxb0YwMTBBVHZfak1GMnNxb2xqalk4bzo=",
    },
  });
  export default {
  AddLead(value) {
    return authApi1.post("/prod/add_form_lead", value);
  },  
   interakt(value) {
    return authApi2.post("https://api.interakt.ai/v1/public/track/users/", value);
  }  
}
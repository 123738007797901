import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
 
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./backimg.css";
import FormControl from '@mui/material/FormControl';
import authApi1 from '../services/AuthAPI'
import authApi2 from '../services/AuthAPI'
import swal from 'sweetalert'; 
import * as Loader from "react-loader-spinner";
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      
      <Link color="inherit" href="https://mobiezy.com/">
       Mobiezy
       
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      
    </Typography>
     
     //timeout={1000} //3 secs
 
  );
}

const theme = createTheme();

 function SignUp() {

 
const [loader, setLoader] = React.useState(false); 
  const [pmode, setpmode] = React.useState(''); 

  const handleSubmit =async (event) => { 
    setLoader(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get('Network'));
  
        if(data.get('City')===""||data.get('phone')===""||data.get('ContactName')==="")
    {
      setLoader(false);
      swal(
        "Error",
        `Missing Field`,
        "warning"
      );
       
    }
    else
    {
          let config=JSON.stringify({
            Network: data.get('Network'),
            City: data.get('City'),
            phone:data.get('phone'),
            MSO:data.get('MSO'),
            ContactName:data.get('ContactName'),
            Remarks:data.get('Remarks'),
            flag:"B"
          });
          console.log(config);

         await authApi1.AddLead(config).then((response) => {
   
            if(response.data.p_out_mssg_flg==="S")
            {
             
               console.log("SUCCESS")

               var newData=JSON.stringify(
                {
     "phoneNumber":data.get('phone'),//phone
    "countryCode": "+91",
    "traits": {
        "name": data.get('ContactName'),//LCONAme,
        "Network":data.get('Network'),
         "STATE":data.get('City'),//City
        "email": "akshayakvaidya@gmail.com",
         "notification_type":"true1",
        "udf1":"EXPO"

    },
    "tags": ["EXPO"]
}
               );
               console.log(newData);


        authApi2.interakt(newData).then((response) => {
          setLoader(false);
          swal(
            "SUCCESS",
            `Record Submitted Successfully`,
            "success"
          );
         }).catch((e)=>{
          setLoader(false);
            console.log(e);
         })  
       

            }
            else{
             setLoader(false);
                swal(
                    "Error",
                    `Try Again....`,
                    "warning"
                  );
            }
        
         
          
           
         }).catch((e)=>{
          setLoader(false);
            console.log(e);
         }) 
    }
 
    
    
 
  };
  const handleChange = (event) => {
     console.log(event.target.value);
    setpmode(event.target.value);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className="outer">
      <div className="inner">
        <div className="body">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        
            <img alt="mobiezy" height='48' width='200' src='https://banners-cableguy.s3.us-west-2.amazonaws.com/mobiezyLogo.png' />
          <Typography component="h6" variant="h6">
            Please Fill the Form to Register
          </Typography>
         
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="ContactName"
                  label="LCO Name"
                  name="ContactName" 

                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  type="number"
                  width="10"
                  maxLength="10"
                  autoComplete="number"
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                }}
                />
               
              </Grid>
              <Grid item xs={12} sm={6}>
               <TextField
                  required
                  fullWidth
                  id="City"
                  label="City"
                  name="City"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
              
                  autoComplete="given-name"
                  name="Network"
                  
                  fullWidth
                  id="Network"
                  label="Network Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                
                  autoComplete="given-name"
                  name="MSO"
                   fullWidth
                  id="MSO"
                  label="MSO"

                />
              </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                
                  autoComplete="given-name"
                  name="Remarks"
                   fullWidth
                  id="Remarks"
                  label="Remarks"

                />
              </Grid>
              
            </Grid>
            {loader ?  <div   style={{
       display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
      }}><Loader.ThreeDots
     type="Circles"
     color="#dc1c2c"
     height={50}
     width={100}/></div> : <Button
     type="submit"
     fullWidth
     variant="contained"
     sx={{ mt: 3, mb: 2 }}
   >
    Submit
   </Button>
 }
            
            
          </Box>
        </Box>
        </div>
        </div>
        </div>
              <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
export default SignUp;

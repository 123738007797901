import * as React from 'react';
import Reg from './component/Signup';
   
import { Routes, Route, BrowserRouter } from "react-router-dom";

export default function App() {
 

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Reg />}/> 
       
    </Routes>
  </BrowserRouter>

  );
}